import htmlToElement from 'html-to-element';
import { addEventListenerOnce } from './event-listener';

const _defaultOptions = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        transform: 'translateY(-400%)',
        transition: 'transform .5s ease-in-out',
        width: '100%',
        willChange: 'transform',
        zIndex: 100
    },
    message: {
        backgroundColor: '#000000',
        maxWidth: '100%',
        padding: '2rem 4rem 2rem 4rem'
    },
    options: {
        timeout: 3000
    }
};

let mergedOptions = {};
let $toast = null;
let $message = null;

/**
 * Converts camel case to kebab case
 *
 * @param string
 * @returns {string}
 */
const camelToKebab = string => {
    return string
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase();
};

/**
 * Converts options to style attributes
 *
 * @param options
 * @returns {string}
 */
const createStyling = options => {
    let styling = '';

    Object.keys(options).forEach(key => {
        styling += `${camelToKebab(key)}: ${options[key]};`;
    });

    return styling;
};

/**
 * Initialize the toast
 *
 * @param options
 */
export const init = (options = { wrapper: {}, message: {}, options: {} }) => {
    mergedOptions = {
        wrapper: {
            ..._defaultOptions.wrapper,
            ...options.wrapper
        },
        message: {
            ..._defaultOptions.message,
            ...options.message
        },
        options: {
            ..._defaultOptions.options,
            ...options.options
        }
    };

    const toastStyle = createStyling(mergedOptions.wrapper);
    const messageStyle = createStyling(mergedOptions.message);

    $toast = htmlToElement(`<div class="toast" style="${toastStyle}"></div>`);
    $message = htmlToElement(
        `<div class="toast__message" style="${messageStyle}"></div>`
    );

    // noinspection JSCheckFunctionSignatures
    $toast.appendChild($message);
    // noinspection JSCheckFunctionSignatures
    document.body.appendChild($toast);
};

/**
 * Shows the toast
 */
const show = () => {
    $toast.style.transform = 'translateY(0)';
};

/**
 * Hides the toast
 *
 * @param func
 */
const hide = func => {
    $toast.style.transform = 'translateY(-100%)';

    addEventListenerOnce($toast, 'transitionend', () => {
        $message.innerText = '';

        if (typeof func === 'function') {
            func();
        }
    });
};

/**
 * Adds a message to the queue
 *
 * @param message
 * @param func
 */
export const add = (message, func = () => {}) => {
    $message.innerText = message;

    show();

    setTimeout(() => hide(func), mergedOptions.options.timeout);
};
