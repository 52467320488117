$('.set-bg').each(function() {
    var bg = $(this).data('setbg');
    $(this).css('background-image', 'url(' + bg + ')');
});

$('.gallery').find('.gallery-item').each(function() {
    var pi_height1 = $(this).outerWidth(true),
        pi_height2 = pi_height1/2;

    if($(this).hasClass('grid-long') && window_w > 991){
        $(this).css('height', pi_height2);
    }else{
        $(this).css('height', Math.abs(pi_height1));
    }
});
