// import './utils/console';

import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import './polyfills';
import './assets-loader';
import './libs';

import * as toast from './utils/toast';

const toastOptions = {
    message: {
        backgroundColor: '#30caa0',
        color: '#000000'
    }
};

toast.init(toastOptions);

const isDevelopment = process.env.NODE_ENV === 'development';
let refreshing = false;

if ('serviceWorker' in navigator && !isDevelopment) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) {
            return;
        }

        refreshing = true;

        toast.add(
            'Eine neue Version der Seite ist verfügbar, lade neu...',
            () => location.reload()
        );
    });

    window.addEventListener('load', () => runtime.register());
}

import './components/accordions';
import './components/background';
import './components/navigation';
import './components/slider';
import * as scroller from './components/scroll';
import * as form from './components/form';

form.init();
scroller.init();
