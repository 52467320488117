export const init = () => {
    const $form = document.forms[1];
    const $checkBox = $form.querySelector('#gdpr-check');
    const $submitButton = $form.querySelector('[type="submit"]');

    if ($checkBox && $submitButton) {
        $submitButton.disabled = !$checkBox.checked;

        $checkBox.addEventListener('click', () => {
            $submitButton.disabled = !$checkBox.checked;
        });
    }
};
