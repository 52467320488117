import * as scroll from '../utils/scroll-to';

export const init = () => {
    const hash = window.location.hash;

    if (hash === '#object-details') {
        const $element = document.querySelector('.object-details');
        const position = $element && $element.offsetTop || 0;

        scroll.scrollTo(position);
    }
}
